import {
  E_productGroup,
  E_ProductType,
  E_ProductStatus,
  E_ReservationPricingStatus,
  E_FGW_Vendor,
  E_Deals_Vendor,
  E_HGW_Vendor,
  E_All_Vendors,
  E_ClientClubType,
  E_PaymentStatus,
  E_Site_ProductTypeNames,
  E_VendorNames,
  E_agent_statuses,
  E_Device,
  E_PaymentStatuses,
  E_SubsidyStatus,
  E_SubsidyStatusName,
  E_CouponStatus,
  E_CouponStatusName,
  E_Site_ProductType,
  E_OrganizedType,
  E_DealType,
  E_CapacityType,
  E_ProductP2DStatus
} from "./enums"
import memoizeOne from "memoize-one"
import {
  SHORT_DATE_FORMAT,
  SUBSIDY_ORDER,
  SUBSIDY_CLIENT,
  SHIKUM,
  UTC_DATE_TIME_FORMAT,
  DB_DATE_TIME_FORMAT,
  DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ARKIA,
  DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ATLANTIS,
  DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ISRAIR,
  DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ARKIA,
  DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ATLANTIS,
  DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ISRAIR,
  SHIKUM_CANCELLATIONS
} from "./consts"
import moment from "moment"

export function isProductInGroup(productType, productGroup) {
  if (productGroup == E_productGroup.All) return true

  switch (productGroup) {
    case E_productGroup.Domestic:
      return (
        productType == E_ProductType.DomesticFlights ||
        productType == E_ProductType.DomesticHotels ||
        productType == E_ProductType.DomesticFlightAndHotel
      )
    case E_productGroup.Abroad:
      return (
        productType == E_ProductType.Vacation ||
        productType == E_ProductType.Ski ||
        productType == E_ProductType.Event ||
        productType == E_ProductType.FlyAndDrive ||
        productType == E_ProductType.OrganizedTours ||
        productType == E_ProductType.Hotels ||
        productType == E_ProductType.Flights ||
        productType == E_ProductType.FlightAndHotel ||
        productType == E_ProductType.OrganizedCruiseTour ||
        productType == E_ProductType.Cruise ||
        productType == E_ProductType.OrganizedPersonalTour
      )
    default:
      return false
  }
}

export function removeFromArray(originArr, value) {
  let arr = [...originArr]
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === value) {
      arr.splice(i, 1)
    }
  }
  return arr
}

export const getVendorsList = memoizeOne((productGroup) => {
  let vendorList = []
  if (
    productGroup == E_productGroup.All ||
    productGroup == E_productGroup.Domestic
  ) {
    //domestic hotels
    vendorList.push(E_All_Vendors.Atlantis)
    vendorList.push(E_All_Vendors.Yaya)
    vendorList.push(E_All_Vendors.MiniHotels)
    vendorList.push(E_All_Vendors.Silverbyte)
    vendorList.push(E_All_Vendors.Dan)
    vendorList.push(E_All_Vendors.Isrotel)

    //domestic flights
    vendorList.push(E_All_Vendors.Arkia)
    vendorList.push(E_All_Vendors.Israir)
  }

  if (
    productGroup == E_productGroup.All ||
    productGroup == E_productGroup.Abroad
  ) {
    //Organized
    vendorList.push(E_All_Vendors.Odyssea)

    //Abroad hotels
    vendorList.push(E_All_Vendors.HotelBeds)
    vendorList.push(E_All_Vendors.OTS)
    vendorList.push(E_All_Vendors.SabreLDS)
    vendorList.push(E_All_Vendors.Nirvana)
    vendorList.push(E_All_Vendors.GoGlobal)

    //domestic flights
    vendorList.push(E_All_Vendors.Amadeus)
    vendorList.push(E_All_Vendors.TravelFusion)

    //Abroad Deals
    vendorList.push(E_All_Vendors.OdysseaRetail)
  }

  return vendorList
})

export function convertVendor(productType, supplier) {
  switch (productType) {
    case E_ProductType.DomesticFlights:
    case E_ProductType.Flight:
      switch (supplier) {
        case E_FGW_Vendor.Arkia:
          return E_All_Vendors.Arkia //"ארקיע"
        case E_FGW_Vendor.Amadeus:
          return E_All_Vendors.Amadeus // "אמדאוס"
        case E_FGW_Vendor.TravelFusion:
          return E_All_Vendors.TravelFusion // "TravelFusion"
        case E_FGW_Vendor.SabreLDS:
          return E_All_Vendors.SabreLDS // "SabreLDS"
        case E_FGW_Vendor.Odyssea:
          return E_All_Vendors.Odyssea // "אודיסאה"
        case E_FGW_Vendor.Atlantis:
          return E_All_Vendors.Atlantis // "אטלנטיס"
        case E_FGW_Vendor.Israir:
          return E_All_Vendors.Israir
        default:
          return E_All_Vendors.Undefined // "FlightsGW"
      }
    case E_ProductType.DomesticHotels:
    case E_ProductType.Hotel:
      switch (supplier) {
        case E_HGW_Vendor.Atlantis:
          return E_All_Vendors.Atlantis // "אטלנטיס"
        case E_HGW_Vendor.Yaya:
          return E_All_Vendors.Yaya // "Yaya"
        case E_HGW_Vendor.MiniHotels:
          return E_All_Vendors.MiniHotels // "מיני הוטלס"
        case E_HGW_Vendor.Silverbyte:
          return E_All_Vendors.Silverbyte // "Silverbyte"
        case E_HGW_Vendor.Dan:
          return E_All_Vendors.Dan // "דן"
        case E_HGW_Vendor.Isrotel:
          return E_All_Vendors.Isrotel // "ישרוטל"
        case E_HGW_Vendor.HotelBeds:
          return E_All_Vendors.HotelBeds // "HotelBeds"
        case E_HGW_Vendor.Odyssea:
          return E_All_Vendors.Odyssea // "אודיסאה"
        case E_HGW_Vendor.OTS:
          return E_All_Vendors.OTS // "OTS"
        case E_HGW_Vendor.SabreLDS:
          return E_All_Vendors.SabreLDS // "SabreLDS"
        case E_HGW_Vendor.Nirvana:
          return E_All_Vendors.Nirvana // "Nirvana"
        case E_HGW_Vendor.GoGlobal:
          return E_All_Vendors.GoGlobal
        default:
          return E_All_Vendors.Undefined // "HotelsGW"
      }
    case E_ProductType.Deals:
      switch (supplier) {
        case E_Deals_Vendor.OdysseaWholesale:
          return E_All_Vendors.Odyssea // "אודיסאה סיטונאות"
        case E_Deals_Vendor.OdysseaRetail:
          return E_All_Vendors.OdysseaRetail // "אודיסאה קמעונאות"
        case E_Deals_Vendor.SabreLDS:
          return E_All_Vendors.SabreLDS // "SabreLDS"
        default:
          return E_All_Vendors.Undefined // "Other"
      }
    case E_ProductType.OrganizedTour:
      return E_All_Vendors.Odyssea // "אודיסאה"
    default:
      return null
  }
}
export function getGroupRemarksKeys(vendor) {
  let RemarksCancellationGroupsKeys
  let RemarksTransactionGroupsKeys
  switch (vendor) {
    case E_FGW_Vendor.Arkia:
      RemarksCancellationGroupsKeys = {
        [DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ARKIA]:
          DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ARKIA
      }
      RemarksTransactionGroupsKeys = {
        [DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ARKIA]:
          DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ARKIA
      }
      break
    case E_FGW_Vendor.Israir:
      RemarksCancellationGroupsKeys = {
        [DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ISRAIR]:
          DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ISRAIR
      }
      RemarksTransactionGroupsKeys = {
        [DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ISRAIR]:
          DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ISRAIR
      }
      break
    case E_FGW_Vendor.Atlantis:
      RemarksCancellationGroupsKeys = {
        [DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ATLANTIS]:
          DOMESTIC_FLIGHTS_CANCELLATION_REMARKS_ATLANTIS
      }
      RemarksTransactionGroupsKeys = {
        [DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ATLANTIS]:
          DOMESTIC_FLIGHTS_TRANSACTION_REMARKS_ATLANTIS
      }
      break
  }
  return { RemarksCancellationGroupsKeys, RemarksTransactionGroupsKeys }
}
export const getProductList = memoizeOne((productGroup) => {
  let productList = []
  if (
    productGroup == E_productGroup.All ||
    productGroup == E_productGroup.Domestic
  ) {
    //domestic hotels
    productList.push(E_Site_ProductType.DomesticHotels)

    //domestic flights
    productList.push(E_Site_ProductType.DomesticFlights)

    //DomesticFlightAndHotel
    productList.push(E_Site_ProductType.DomesticFlightAndHotel)
  }

  if (
    productGroup == E_productGroup.All ||
    productGroup == E_productGroup.Abroad
  ) {
    //Abroad hotels
    productList.push(E_Site_ProductType.Hotels)

    //domestic flights
    productList.push(E_Site_ProductType.Flights)

    //Abroad Deals
    productList.push(E_Site_ProductType.Vacation)
    productList.push(E_Site_ProductType.Ski)

    //FlightAndHotel
    productList.push(E_Site_ProductType.FlightAndHotel)

    //Organized
    productList.push(E_Site_ProductType.OrganizedTours)
    productList.push(E_Site_ProductType.OrganizedPersonalTour)
    productList.push(E_Site_ProductType.Cruise)
    productList.push(E_Site_ProductType.OrganizedCruiseTour)
  }
  return productList
})

export const agentSortMem = memoizeOne((agents) => {
  let agentsSorted = [...agents]
  return agentsSorted.sort(function (a, b) {
    var nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase()
    if (nameA < nameB)
      //sort string ascending
      return -1
    if (nameA > nameB) return 1
    return 0 //default return value (no sorting)
  })
})

export function getClubName(clientClubType) {
  switch (clientClubType) {
    case E_ClientClubType.Hever:
      return "חבר"
    case E_ClientClubType.GoodClub:
      return "מועדון טוב"
    case E_ClientClubType.Bezeq:
      return "בזק"
    case E_ClientClubType.Kranot:
      return "קרנות"
    case E_ClientClubType.Shabas:
      return "שבס"
    case E_ClientClubType.Unilever:
      return "יוניליבר"
    case E_ClientClubType.Yahad:
      return "יחד"
    case E_ClientClubType.AshmoretClub:
      return "אשמורת"
    case E_ClientClubType.Ormat:
      return "אורמת"
    case E_ClientClubType.Katca:
      return "קצאא"
    case E_ClientClubType.EFlights:
      return "איפלייטס"
    case E_ClientClubType.Regular:
      return ""
    default:
      return "אחר"
  }
}

export function getProductName(productType) {
  return E_Site_ProductTypeNames[productType]
}

export function getVendorsName(vendors) {
  let vendorsStr = ""
  if (vendors && Array.isArray(vendors)) {
    vendors.forEach((vendor, i) => {
      if (i > 0) vendorsStr += ", "
      vendorsStr += E_VendorNames[vendor]
    })
  }
  return vendorsStr
}
export function convertDate(
  date,
  timeFormat = DB_DATE_TIME_FORMAT,
  pattern = "DD.MM.YY"
) {
  return moment(date, timeFormat).format(pattern)
}
export function getStatusName(status) {
  return E_agent_statuses[status]
}

export function getDeviceName(device) {
  switch (device) {
    case E_Device.Site:
      return "דסקטופ"
    case E_Device.Mobile:
      return "מובייל"
    default:
      return "אחר"
  }
}

export function getPaymentError(status, isOrderPage) {
  switch (status) {
    case E_PaymentStatus.AuthorizeError:
    case E_PaymentStatus.AuthorizeSupplierError:
      return "שגיאה בתפיסת מסגרת"
    case E_PaymentStatus.AuthorizeError:
    case E_PaymentStatus.CaptureError:
    case E_PaymentStatus.CaptureSupplierError:
      return "שגיאה בחיוב הכרטיס"
    case E_PaymentStatus.PostChargeError:
      return "שגיאה בשליחת קבלה"
    case E_PaymentStatus.Undefined:
      return isOrderPage && "לא חויב"
    default:
      return null
  }
}

export function getProductError(status, isOrderPage) {
  switch (status) {
    case E_ProductStatus.PreBookError:
      return "קדם הזמנה - שגיאה כללית"
    case E_ProductStatus.PreBookSupplierFailure:
      return "קדם הזמנה - הזמנה לא מאושרת "
    case E_ProductStatus.ReservationError:
      return "הזמנת טיסה - שגיאה כללית"
    case E_ProductStatus.ReservationSupplierFailure:
      return "הזמנת טיסה - הזמנה לא מאושרת"
    case E_ProductStatus.ReservationRequest:
      return "הזמנת טיסה - נכנסה בריקווסט"
    case E_ProductStatus.BookError:
      return "הזמנת מלון - שגיאה כללית"
    case E_ProductStatus.BookSupplierFailure:
      return "הזמנת מלון - הזמנה לא מאושרת"
    case E_ProductStatus.PelegError:
      return "פתיחת דוקט - שגיאה כללית"
    case E_ProductStatus.PelegRequest:
      return isOrderPage && "דוקט נפתח בריקווסט"
    default:
      return null
  }
}

export function getDocketError(status, isOrderPage) {
  switch (status) {
    case E_ProductP2DStatus.PelegError:
      return "פתיחת דוקט - שגיאה כללית"
    case E_ProductStatus.PelegRequest:
      return isOrderPage && "דוקט נפתח בריקווסט"
    case E_ProductStatus.PelegSuccess:
      return null
    default:
      return null
  }
}

export function getReservationError(status) {
  switch (status) {
    case E_ReservationPricingStatus.ApprovedLoss:
      return "מחיר בהפסד מאושר"
    case E_ReservationPricingStatus.PriceChangeSuccess:
      return "שינוי מחיר מאושר"
    case E_ReservationPricingStatus.PriceChangeApprovedLoss:
      return "שינוי מחיר בהפסד מאושר"
    case E_ReservationPricingStatus.PriceChangeNotApprovedLoss:
      return "שינוי במחיר - הפסד לא מאושר"
    default:
      return null
  }
}

export function getPaymentStatus(status) {
  switch (status) {
    case E_PaymentStatus.AuthorizeSuccess:
    case E_PaymentStatus.CaptureError:
    case E_PaymentStatus.CaptureSupplierError:
      return E_PaymentStatuses.J5
    case E_PaymentStatus.CaptureSuccess:
    case E_PaymentStatus.PostChargeError:
    case E_PaymentStatus.PostChargeSuccess:
    case E_PaymentStatus.Success:
      return E_PaymentStatuses.J4
    case E_PaymentStatus.Undefined:
    default:
      return E_PaymentStatuses.Undefined
  }
}

export function tryParseJSON(str, strName, defaultValue?) {
  let parsedJSON = defaultValue || null
  try {
    parsedJSON = JSON.parse(str)
  } catch (e) {
    console.log("Unable to parse data", strName)
  }
  return parsedJSON
}

export function roundPrice(num) {
  return Math.round(num * 100) / 100
}

export function formatLocalDateTime(UTCDate) {
  if (UTCDate) {
    const date = moment.utc(UTCDate, UTC_DATE_TIME_FORMAT)
    var localTime = moment.utc(date).toDate()
    return moment(localTime).format(`${SHORT_DATE_FORMAT} HH:mm`)
  }
  return ""
}

export function objectKeysToCamelCase(origObj) {
  try {
    if (origObj) {
      if (Array.isArray(origObj)) {
        return origObj.map((val) =>
          typeof val === "object" ? objectKeysToCamelCase(val) : val
        )
      } else {
        return Object.keys(origObj).reduce(function (newObj, key) {
          let val = origObj[key]
          let newVal =
            typeof val === "object" ? objectKeysToCamelCase(val) : val
          newObj[toCamelCase(key)] = newVal
          return newObj
        }, {})
      }
    }
    return null
  } catch (e) {
    return null
  }
}

function toCamelCase(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return "" // or if (/\s+/.test(match)) for white spaces
    return index == 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export const getProductGroup = memoizeOne((productType) => {
  switch (productType) {
    case E_ProductType.DomesticFlights:
    case E_ProductType.DomesticHotels:
    case E_ProductType.DomesticFlightAndHotel:
      return E_productGroup.Domestic
    case E_ProductType.Deals:
    case E_ProductType.OrganizedTour:
    case E_ProductType.Hotel:
    case E_ProductType.Flight:
    case E_ProductType.FlightAndHotel:
      return E_productGroup.Abroad
    default:
      return E_productGroup.Abroad
  }
})

export function getCapacityStr(passengers) {
  let capacity = passengers ? objectKeysToCamelCase(passengers) : null
  let str = []
  if (capacity && capacity.adults > 0) {
    str.push(capacity.adults > 1 ? `${capacity.adults} מבוגרים` : `מבוגר אחד`)
  }
  if (capacity && capacity.children > 0) {
    str.push(capacity.children > 1 ? `${capacity.children} ילדים` : `ילד אחד`)
  }
  if (capacity && capacity.infants > 0) {
    str.push(capacity.infants > 1 ? `${capacity.infants} תינוקות` : `תינוק אחד`)
  }
  return str.join(", ")
}

export function getCapacityType(capacityName) {
  switch (capacityName) {
    case "Adults":
    case "adults":
      return E_CapacityType.Adults
    case "Children":
    case "children":
      return E_CapacityType.Children
    case "Infants":
    case "infants":
      return E_CapacityType.Infants
    default:
      return null
  }
}

export function isNullOrEmpty(str) {
  return typeof str === "undefined" || str === null || str === ""
}

export const getProductHtmlRemarksKey = (productType) => {
  switch (productType) {
    case E_ProductType.DomesticFlights:
    case E_ProductType.DomesticHotels:
      return "DomesticOrderMailContactInfo"
    case E_ProductType.Deals:
    case E_ProductType.OrganizedTour:
    case E_ProductType.Hotel:
    case E_ProductType.Flight:
    case E_ProductType.FlightAndHotel:
    default:
      return "OrganizedOrderMailContactInfo"
  }
}

export function toFixed2(num) {
  return +(Math.round(+(num + "e+2")) + "e-2")
}

export function getSubsidyError(status, isOrderPage) {
  if (
    status != E_SubsidyStatus.Success &&
    (isOrderPage || status != E_SubsidyStatus.Undefined)
  ) {
    return E_SubsidyStatusName[status]
  }
  return null
}

export function getCouponError(status, isOrderPage) {
  if (
    (status != E_CouponStatus.Undefined || isOrderPage) &&
    status != E_CouponStatus.ValidationSuccess &&
    status != E_CouponStatus.RedemptionSuccess
  ) {
    return E_CouponStatusName[status]
  }
  return null
}

export function formatDuration(durationInMinutes) {
  return durationInMinutes && durationInMinutes > 0
    ? moment
        .utc(moment.duration(durationInMinutes, "minutes").asMilliseconds())
        .format("HH:mm")
    : null
}

export function isInString(str, subStr) {
  return (
    str &&
    subStr &&
    str.toString().toLowerCase().indexOf(subStr.toLowerCase()) != -1
  )
}

export const getApiUrlByResourceType = (resourceType) => {
  switch (resourceType) {
    case SUBSIDY_ORDER:
      return `/subsidy/getorders`
    case SUBSIDY_CLIENT:
      return `/subsidy/getcustomers`
    case SHIKUM:
      return `/shikum/getOrders`
    case SHIKUM_CANCELLATIONS:
      return `/shikum/getOrders`
    default:
      return `/orders/getorders`
  }
}

export const getDeleteUrlByResourceType = (resourceType) => {
  switch (resourceType) {
    case SUBSIDY_ORDER:
      return `/subsidy/delete`
    default:
      return null
  }
}

export const getUpdateUrlByResourseType = (resourceType) => {
  switch (resourceType) {
    case SUBSIDY_ORDER:
      return `/subsidy/update`
    default:
      return null
  }
}

export function calcSum(arrayOfObjects, getValuFunction) {
  let sum = 0
  try {
    if (arrayOfObjects && arrayOfObjects.length > 0) {
      for (let i = 0; i < arrayOfObjects.length; i++) {
        if (arrayOfObjects[i]) {
          let value = getValuFunction(arrayOfObjects[i])
          if (value) {
            sum += value
          }
        }
      }
    }
  } catch (e) {
    console.log(e, "Error in calcSum")
  }
  return sum
}

export function convertProductType(productType, subProductType) {
  switch (productType) {
    case E_ProductType.DomesticFlights:
      return E_Site_ProductType.DomesticFlights
    case E_ProductType.Flight:
      return E_Site_ProductType.Flights
    case E_ProductType.DomesticHotels:
      return E_Site_ProductType.DomesticHotels
    case E_ProductType.Hotel:
      return E_Site_ProductType.Hotels
    case E_ProductType.Deals:
      switch (subProductType) {
        case E_DealType.Ski:
          return E_Site_ProductType.Ski
        default:
          return E_Site_ProductType.Vacation
      }
    case E_ProductType.OrganizedTour:
      switch (subProductType) {
        case E_OrganizedType.OrganizedPersonalTour:
          return E_Site_ProductType.OrganizedPersonalTour
        case E_OrganizedType.Cruise:
          return E_Site_ProductType.Cruise
        case E_OrganizedType.OrganizedCruiseTour:
          return E_Site_ProductType.OrganizedCruiseTour
        default:
          return E_Site_ProductType.OrganizedTours
      }
    case E_ProductType.FlightAndHotel:
      return E_Site_ProductType.FlightAndHotel
    case E_ProductType.DomesticFlightAndHotel:
      return E_Site_ProductType.DomesticFlightAndHotel
    default:
      return null
  }
}

export function groupBy(list, keyGetter) {
  const map = {}
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map[key]
    if (!collection) {
      map[key] = [item]
    } else {
      collection.push(item)
    }
  })
  return map
}

export function getPriceChange(priceChanges, type, productType?) {
  if (priceChanges && priceChanges.length > 0) {
    const priceChangesOfType =
      productType != E_ProductType.DomesticFlight
        ? priceChanges.find((x) => x.priceSaleType == type)
        : priceChanges.find((x) => x.PriceSaleType == type)
    if (priceChangesOfType) {
      if (productType != E_ProductType.DomesticFlight) {
        const { id, name, value, priceSaleValueType, comment } =
          priceChangesOfType
        return {
          id,
          name,
          value,
          type: priceSaleValueType,
          comment
        }
      } else {
        const { Id, Name, Value, PriceSaleValueType, Comment } =
          priceChangesOfType

        return {
          id: Id,
          name: Name,
          value: Value,
          type: PriceSaleValueType,
          comment: Comment
        }
      }
    }
    return null
  }
  return null
}

export function minutesToTime(minutes, isDurationSlider) {
  var hours1: any = Math.floor(minutes / 60)
  var minutes1: any = Math.floor(minutes - hours1 * 60)

  if (!isDurationSlider) {
    //if (hours1 > 23) hours1 -= 24;
    while (hours1 > 23) {
      hours1 -= 24
    }
  }

  if (hours1 < 10) hours1 = "0" + hours1
  if (minutes1 < 10) minutes1 = "0" + minutes1

  return hours1 + ":" + minutes1
}

export default function mergeArrays(arraysOfArrays) {
  return [].concat(...arraysOfArrays)
}

export function getCountryImage(country) {
  return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`
}

export function isObject(obj: any) {
  return Object.prototype.toString.call(obj) === "[object Object]"
}

export function getAgentDepartments(req) {
  return req &&
    req.session &&
    req.session.user &&
    req.session.user.isAgentManager
    ? [...req.session.user.mngGroups, "domestic"]
    : []
}
