import { all } from "redux-saga/effects"

export const E_ClientClubType: any = {
  Regular: 0,
  Hever: 1,
  GoodClub: 2,
  Bezeq: 3,
  Kranot: 4,
  Unilever: 5,
  Hot: 6,
  Yahad: 8,
  Shabas: 10,
  EshetClub: 11,
  AshmoretClub: 12,
  Groups: 13,
  Ormat: 14,
  Katca: 15,
  EFlights: 16
}

export const E_ProductType: any = {
  Undefined: 0,
  OrganizedTour: 1,
  Insurance: 2,
  Flight: 4,
  DomesticHotels: 5,
  DomesticFlights: 6,
  DomesticPackage: 7,
  Deals: 8,
  LandingPage: 9,
  Hotel: 10,
  FlightAndHotel: 11,
  DomesticFlightAndHotel: 13
}

export const E_Site_ProductType: any = {
  Undefined: 0,
  DomesticFlights: 1,
  DomesticHotels: 2,
  Vacation: 3,
  Domestic: 4,
  Ski: 5,
  Event: 6,
  FlyAndDrive: 7,
  OrganizedTours: 8,
  Hotels: 9,
  Flights: 10,
  FlightAndHotel: 11,
  JordanAndSinayHotels: 12,
  OrganizedCruiseTour: 13,
  Cruise: 14,
  OrganizedPersonalTour: 15,
  DomesticFlightAndHotel: 16,
  All: 99
}

export const E_Site_ProductTypeNames: any = {
  1: "טיסות פנים",
  2: "מלונות פנים",
  3: "חבילות נופש",
  5: "חבילת סקי",
  6: "חבילת ספורט",
  7: "טוס וסע",
  8: "טיול מאורגן",
  9: 'מלונות בחו"ל',
  10: 'טיסות לחו"ל',
  11: "טיסה + מלון",
  13: "שייט מאורגן",
  14: "חבילת שייט",
  15: "טיול פרטי",
  16: "טיסה+ מלון פנים"
}

export const E_FGW_Vendor: any = {
  FlightsGW: 0,
  Arkia: 1,
  Amadeus: 2,
  TravelFusion: 3,
  SabreLDS: 4,
  Odyssea: 5,
  Atlantis: 6,
  Israir: 7
}

export const E_FGW_VendorName: any = {
  0: "FlightsGW",
  1: "ארקיע",
  2: "אמדאוס",
  3: "Travel Fusion",
  4: "SabreLDS",
  5: "אודיסאה",
  6: "אטלנטיס",
  7: "ישראייר"
}

export const E_Deals_Vendor: any = {
  Undefined: 0,
  OdysseaWholesale: 1,
  OdysseaRetail: 2,
  SabreLDS: 3,
  DynamicSource: 4
}

export const E_HGW_Vendor: any = {
  HotelsGW: 0,
  Atlantis: 1,
  Yaya: 2,
  MiniHotels: 3,
  Silverbyte: 4,
  Dan: 5,
  Isrotel: 6,
  HotelBeds: 7,
  Odyssea: 8,
  OTS: 9,
  SabreLDS: 10,
  Nirvana: 11,
  GoGlobal: 14
}

export const E_TG_Vendor: any = {
  TourGw: 0,
  Odyssea: 1,
  SabreLDS: 2
}

export const E_All_Vendors: any = {
  Undefined: 0,
  Atlantis: 1,
  Yaya: 2,
  MiniHotels: 3,
  Silverbyte: 4,
  Dan: 5,
  Isrotel: 6,
  HotelBeds: 7,
  Odyssea: 8,
  Arkia: 9,
  Amadeus: 10,
  TravelFusion: 11,
  SabreLDS: 12,
  OdysseaRetail: 13,
  OTS: 14,
  Nirvana: 15,
  Israir: 16,
  GoGlobal: 17
}

export const E_VendorNames: any = {
  1: "אטלנטיס",
  2: "Yaya",
  3: "מיני הוטלס",
  4: "Silverbyte",
  5: "דן",
  6: "ישרוטל",
  7: "HotelBeds",
  8: "אודיסאה",
  9: "ארקיע",
  10: "אמדאוס",
  11: "Travel Fusion",
  12: "SabreLDS",
  13: "אודיסאה",
  14: "OTS",
  15: "Nirvana",
  16: "ישראייר",
  17: "GoGlobal"
}

export const E_OrderStatuses: any = {
  1: "הזמנה לא הושלמה",
  2: "הזמנה בתהליך",
  3: "הזמנה הושלמה",
  4: "הזמנה בריקווסט",
  5: "הזמנה לא תקינה"
}

export const E_OrderStatus: any = {
  Undefined: 0,
  Error: 1,
  InProccess: 2,
  Success: 3,
  Request: 4,
  ValidationError: 5
}

export const E_PaymentStatuses: any = {
  Undefined: 0,
  J5: 1,
  J4: 2
}

export const E_PaymentStatusesName: any = {
  1: "J5",
  2: "J4"
}

export const E_PaymentMethods = {
  Undefined: 0,
  PeleCard: 1,
  Paypal: 2
}

export const E_PaymentMethodsNames = {
  1: "כרטיס אשראי",
  2: "פייפאל",
  99: "סבסוד",
  100: "קופון"
}

export const E_PaymentMethodsImages = {
  [E_PaymentMethods.PeleCard]: "/static/images/payment/Pelecard.jpg",
  [E_PaymentMethods.Paypal]: "/static/images/payment/Paypal.png"
}

export const E_ClubsLogos = {
  [E_ClientClubType.Hever]: "/static/images/clubs/logo-hever-club.png",
  [E_ClientClubType.GoodClub]: "/static/images/clubs/moadon-tov-logo.png",
  [E_ClientClubType.Bezeq]: "/static/images/clubs/logo-bezeq.png",
  [E_ClientClubType.Kranot]: "/static/images/clubs/kranot-logo.png",
  [E_ClientClubType.Unilever]: "/static/images/clubs/unilever-logo.png",
  [E_ClientClubType.Hot]: "/static/images/clubs/hot-logo.png",
  [E_ClientClubType.Shabas]: "/static/images/clubs/logo-shabas.png",
  [E_ClientClubType.Yahad]: "/static/images/clubs/logo-yahad.png",
  [E_ClientClubType.EshetClub]: "/static/images/clubs/moadon-logo.png",
  [E_ClientClubType.AshmoretClub]: "/static/images/clubs/ashmoret-logo.png",
  [E_ClientClubType.Groups]: "/static/images/clubs/groups.svg",
  [E_ClientClubType.Ormat]: "/static/images/clubs/ormat-logo.png",
  [E_ClientClubType.Katca]: "/static/images/clubs/katca-logo.jpg",
  [E_ClientClubType.EFlights]: "/static/images/clubs/eflights-red-logo.svg"
}

export const E_agent_statuses = {
  1: "טופל",
  2: "בטיפול",
  3: "הועבר למועדון",
  4: "בוטל"
}

export const E_productGroup = {
  All: 0,
  Domestic: 1,
  Abroad: 2
}

export const E_Device: any = {
  Site: 0,
  Mobile: 1
}

export const E_PaymentStatus: any = {
  Undefined: 0,
  AuthorizeError: 1,
  AuthorizeSupplierError: 2,
  AuthorizeSuccess: 3,
  CaptureError: 4,
  CaptureSupplierError: 5,
  CaptureSuccess: 6,
  PostChargeError: 7,
  PostChargeSuccess: 8,
  Success: 9
}

export const E_DaysNames: any = {
  0: "א",
  1: "ב",
  2: "ג",
  3: "ד",
  4: "ה",
  5: "ו",
  6: "ש"
}

export const E_InternalFlightType: any = {
  Regular: 0,
  LowCost: 1
}

export const E_InternalFlightTypeNames: any = {
  0: "טיסה סדירה",
  1: "Low Cost"
}

export const E_Currency: any = {
  ILS: 0,
  USD: 1,
  EUR: 2,
  GBP: 3,
  None: 4
}

export const E_CurrencySymbol: any = {
  0: "₪",
  1: "$",
  2: "€",
  3: "£",
  4: ""
}

export const E_PaymentCardType: any = {
  Undefined: 0,
  Credit: 1,
  Direct: 2
}

export const E_PaymentCardTypeNames: any = {
  1: "אשראי",
  2: "דיירקט"
}

export const E_SubsidyTypeNames: any = {
  1: "בזק"
}

export const E_SubsidyStatus: any = {
  Undefined: 0,
  Error: 1,
  SupplierError: 2,
  Success: 3
}

export const E_SubsidyStatusName: any = {
  0: "לא בוצע סבסוד",
  1: "שגיאה",
  2: "שגיאה",
  3: "אושר"
}

export const E_orderStatusForEmail: any = {
  Undefined: 0,
  InProgress: 1,
  Completed: 2,
  Error: 3
}

export const E_orderStatusForEmailNames: any = {
  [E_orderStatusForEmail.Undefined]: "בחר סטטוס",
  [E_orderStatusForEmail.InProgress]: "הזמנה בטיפול",
  [E_orderStatusForEmail.Completed]: "הזמנה אושרה"
}

export const E_ProductStatus: any = {
  Undefined: 0,
  PreBookError: 1,
  PreBookSupplierFailure: 2,
  PreBookSuccess: 3,
  ReservationError: 4,
  ReservationSupplierFailure: 5,
  ReservationRequest: 6,
  ReservationSuccess: 7,
  BookError: 8,
  BookSupplierFailure: 9,
  BookSuccess: 10,
  IssueError: 11,
  IssueSupplierFailure: 12,
  IssueSuccess: 13,
  PelegError: 14,
  PelegRequest: 15
}

export const E_ReservationPricingStatus: any = {
  Undefined: 0,
  Success: 1,
  ApprovedLoss: 2,
  PriceChangeSuccess: 3,
  PriceChangeApprovedLoss: 4,
  PriceChangeNotApprovedLoss: 5
}

export const E_PassengerTypeNames: any = {
  0: "מבוגר",
  1: "ילד",
  2: "תינוק",
  3: "צעיר",
  4: "פנסיונר"
}

export const E_GenderNames: any = {
  0: "זכר",
  1: "נקבה"
}

export const E_CodeType: any = {
  IataCode: "IATACODE",
  NodeId: "NODEID"
}

export const E_DestinationType: any = {
  City: "City",
  Airport: "Airport",
  Area: "Area"
}

export const E_TaxTypeNames: any = {
  1: "מס",
  2: "היטל",
  3: "מיסים והיטלים נוספים"
}

export const E_DiscountType = {
  Undefind: 0,
  Percents: 1,
  Number: 2
}

export const E_CouponType: any = {
  Undefined: 0,
  Coupon: 1,
  Voucher: 2
}

export const E_FilterTypes: any = {
  Input: 0,
  Checkbox: 1
}

export const E_CouponStatus: any = {
  Undefined: 0,
  ValidationError: 1,
  ValidationSupplierError: 2,
  ValidationSuccess: 3,
  RedemptionError: 4,
  RedemptionSupplierError: 5,
  RedemptionSuccess: 6
}

export const E_CouponStatusName: any = {
  0: "לא חויב",
  1: "ולידצייה - שגיאה כללית",
  2: "ולידצייה - נכשל מול הספק",
  3: "ולידצייה הצליחה",
  4: "מימוש - שגיאה כללית",
  5: "מימוש נכשל מול הספק",
  6: "הקופון מומש"
}

export const E_OrganizedType: any = {
  OrganizedTours: 0,
  OrganizedPersonalTour: 1,
  Cruise: 2,
  OrganizedCruiseTour: 3
}

export const E_DealType: any = {
  Undefined: 0,
  Vacation: 1,
  Ski: 2
}

export const E_MiniRuleType: any = {
  Reissue: 0,
  ReissueNoShow: 1,
  Refund: 2,
  RefundNoShow: 3,
  Eshet: 4
}

export const E_FlightType = {
  Scheduled: 0,
  Charter: 1,
  ScheduledCharter: 2,
  LowCost: 3,
  Unknown: 4,
  Combined: 5
}
export const E_PageType: any = {
  Orders: 0,
  Subsidy: 1,
  AgentOrders: 2
}

export const E_PriceSaleType = {
  Discount: 0,
  Commission: 1,
  Markup: 2,
  AgentCommission: 3
}

export const E_CapacityType: any = {
  Adults: 0,
  Children: 1,
  Infants: 2,
  Youth: 3,
  Senior: 4
}

export const E_ProductP2DStatus: any = {
  Undefined: 0,
  PelegError: 1,
  PelegRequest: 2,
  PelegSuccess: 3
}

export const E_SubClub: any = {
  0: "",
  1: "יוניליור שפע ישראל",
  2: "ישראל טיפוח אישי",
  3: "ישראל מזון",
  4: "גלידת שטראוס"
}

export const E_Hotel_VendorTypes: any = {
  Undefined: 0,
  Giata: 1,
  Odyssea: 2,
  SabreLDS: 3
}
